import { LinearProgress } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import TimeCircular from '../components/TimeCircular';

const Page3 = (props) => {
    const [files, setFiles] = useState([])
    const [totalPage, setTotalPage] = useState()
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [image1, setImage1] = useState(0)
    const [image2, setImage2] = useState(1)
    
    //define variables
    let tvId = localStorage.getItem('location')
    let seconds = props.data.data.flyer_time * 1000
    let _totalPage
    let currentSecond = 1
    let counter = 1
    let currrentPage = 1
    let _image1 = 0
    let _image2 = 1

    //get all files on first initialation
    useEffect(() => {
        getFiles()
    }, [props.data])

    //get files from server
    const getFiles = async () => {
        let { data } = await Axios.get('https://admin.sigzentrum.ch/sig-tv/items/tvs_directus_files?filter[tvs_id][eq]=' + tvId)
        let urls = []
        for (let i = 0; i < data.data.length; i++) {
            let file = await Axios.get('https://admin.sigzentrum.ch/sig-tv/files/' + data.data[i].directus_files_id)
            urls.push(file.data.data.data.full_url)
        }
        setFiles(urls)
        setTotalPage(Math.ceil(urls.length/2))
        _totalPage = Math.ceil(urls.length/2)
        setLoading(false)
    }

    //switch pages
    useEffect(() => {
        const interval = setInterval(() => {            
            if((seconds / 1000) *_totalPage == currentSecond){
                props.switchPage()
            }
            
            // if counter matches given seconds - slide to the next page
            if(counter == seconds / 1000){
                setImage1(_image1 + 2)
                setImage2(_image2 + 2)
                _image1 = _image1 + 2
                _image2 = _image2 + 2
                currrentPage++
                counter = 1
            } else {
                counter++
            }

            currentSecond++            
        }, 1000)

        return () => {
            clearInterval(interval);
        }
    }, [])


    if (loading) {
        return <div className="container-fluid text-center" style={{backgroundColor:props.data.data.flyer_background}}></div>
    }

    return (
        <div className="container-fluid text-center" style={{backgroundColor:props.data.data.flyer_background}}>
            <div className="row">
                <div className="col">
                    <img src={files[image1]} className="flyer"></img>
                </div>
                <div className="col">
                    <img src={files[image2]} className="flyer"></img>
                </div>
            </div>

            <div className="timerCircle" style={{ color: props.data.data.home_color }}>
                <TimeCircular counter={(seconds / 1000) * totalPage} />
            </div>
        </div>
    )
}

export default Page3;