import React from 'react'
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const Reset = () => {
    const history = useHistory()

    const handleClick = () => {
        localStorage.clear()
        history.push('/')
    }

    return (
        <div style={{ textAlign: "center", padding: '5vh' }}>
            <Button
                variant="contained"
                className="reset-button"
                onClick={handleClick}
            >
                Einstellungen zurücksetzen
            </Button>
        </div>
    )
}

export default Reset