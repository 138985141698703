import React from 'react';
import MemberShipPayments from '../components/MemberShipPayments';
import Lesson from '../components/Lesson'
import Clock from '../components/Clock'

const Page1 = (props) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm">
                    <MemberShipPayments data={props.data} switchPage={props.switchPage} setSeconds={props.setSeconds}/>
                </div>
                <div className="col-sm">
                    <div className="row h50">
                        <div className="col-sm page1-logo">
                            {/* <PrayerTime data={props.data} /> */}
                            <img src={props.logo} />
                        </div>
                        <div className="col-sm clock-center">
                            <Clock />
                        </div>
                    </div>
                    <div className="row h50">
                        <div className="col-sm">
                             <Lesson data={props.data} />
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    )
}

export default Page1;