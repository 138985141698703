import React, { useEffect, useState } from 'react';
import Announcement from '../components/Announcement';
import Axios from 'axios';
import TimeCircular from '../components/TimeCircular';

const Page2 = (props) => {
  

  const [progress, setProgress] = useState(0)
  let seconds = props.data.data.home_time * 1000

  //for linerprogress
  let steps
  let current
  let _progress
  let currentSecond = 1


  useEffect(() => {
    //define linearpgoress
    steps = 100 / (seconds / 1000)
    current = steps
    _progress = steps

    const interval = setInterval(() => {

      //change the page
      if ((seconds / 1000) == currentSecond) {
        currentSecond = 1
        props.switchPage()
      }

      //progress stuff
      if (_progress === 100) {
        setProgress(steps)
        current = steps
        _progress = steps
      } else {
        setProgress(current + steps)
        current = current + steps
        _progress = _progress + steps
      }

      currentSecond++
    }, 1000)

    return () => {
      clearInterval(interval);
    }
  }, [])

  return (
    <div className="container-fluid col-100" style={{ backgroundColor: props.data.data.home_background }}>

      {
        props.data.data.home_logo
          ? <img className="page2-logo" alt="logo" src={props.logo}></img>
          : ''
      }

      <Announcement data={props.data} />

      <div className="timerCircle" style={{ color: props.data.data.home_color }}>
        <TimeCircular counter={seconds / 1000} />
      </div>
    </div>
  );
}

export default Page2;