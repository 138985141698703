import { createMuiTheme } from "@material-ui/core";
let primary = '#029E76'
let dark = '#23282D'

const Theme = createMuiTheme({
    palette: {
        primary: {
            main: primary,
        },
        dark: {
            main: dark,
        },
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    },
    overrides: {
        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                borderColor: primary,

                "&$focused": { // increase the specificity for the pseudo class
                    color: primary,
                    borderColor: primary,

                }
            },
        },
        ColorButton: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                color: '#FFFFFF',
                backgroundColor: dark,
                '&:hover': {
                    backgroundColor: '#000000',
                },

            },
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: primary,
                },
                '&:hover:before': {
                    borderBottomColor: [primary, '!important'],
                },
            }
        },
    }
});

export default Theme